.card {
  @include theme-aware("background", "card_background");
  @include theme-aware("color", "card_text_color");
  margin: 0px !important;
  padding: $timeline_spacing;
  box-shadow: $shadows;
  width: $timeline_content-width;
  border-radius: 0 $timeline_bdrs $timeline_bdrs 0;

  &-title {
    padding-top: 10px;
    font-size: 1.2em;

    &-bold {
      font-weight: bold;
    }

    svg {
      padding-top: 5px;
    }
  }

  &-items {
    display: grid;
    margin-top: 15px;
    margin-left: 50px;
    line-height: 2em;

    svg {
      font-size: 1.5em;
      margin-right: 10px;
    }

    span {
      width: auto;
      font-size: 1em;
    }

    &-sm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      width: 100%;
      margin: 10px 50px;
      margin-left: 50px;

      svg {
        font-size: 1.2em;
        margin-right: 10px;
      }

      span {
        font-size: 0.85em;
      }
    }
  }
}

@media (max-width: $max_width_responsive) {
  .card-items {
    margin-left: 25px;

    &-sm {
      margin-left: 25px;
    }
  }
}
