.contacts {
  @include theme-aware("color", "link_color");
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px auto;
  width: 60vw;

  &-info {
    min-width: 300px;
    max-width: 40%;
    justify-content: start;

    &-section {
      margin: 50px auto;
    }

    a {
      @include theme-aware("color", "link_color");
    }
  }

  &-message {
    @include theme-aware("background", "card_background");
    min-width: 350px;
    max-width: 40%;
    padding: 50px 50px;
    text-align: center;

    &-title {
      @include theme-aware("color", "message_title");
      color: #888;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-weight: 400;
    }

    &-form {
      text-align: left;
      min-width: 100%;
      max-width: 100%;
    }
  }
}
