html,
body {
  @include gradient_background_app();
  @include theme-aware("color", "primary_color");
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  @include theme-aware("color", "text_color");
}
