.app {
  display: grid;
  grid-template-rows: 10vh auto 10vh;
  grid-template-areas: "header" "content" "footer";
}

.header {
  @include theme-aware("border-color", "border_color");
  grid-area: "header";
  grid-column: 1/3;
  align-items: center;
  border-bottom: solid;
  border-width: $border_width;
  padding-bottom: 40px;

  &-theme {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    height: auto;
    width: 100%;

    &-switch {
      margin-top: 10px;
      margin-right: 10px;

      svg {
        margin-top: 4px;
        margin-left: 5px;
        font-size: 20px;
      }
    }
  }
}

.content {
  grid-area: "carrer";
  padding-top: 20px;
  padding-bottom: 20px;
  @include gradient_background_content();
}

.footer {
  grid-area: "footer";
  grid-column: 1/3;
  margin: auto;
  font-size: 0.8em;
  color: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  border-top: solid;
  border-width: $border_width;
  @include theme-aware("border-color", "border_color");
}

.toast {
  top: 130px;
}

@media (max-width: $max_width_responsive) {
  .app {
    display: grid;
    grid-template-columns: 6fr;
    grid-template-rows: 15vh auto 10vh;
    grid-template-areas: "header" "carrer" "footer";
  }

  .toast {
    top: 0;
    margin: auto;
    width: 90%;
  }
}
