.menu {
  font-size: 1.3em;
  height: 100%;
  display: flex;
  flex: 1 0 300px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: auto 0;
  list-style: none;

  &-item {
    font-family: "Times New Roman", Times, serif;
    margin: 5px 30px;
    opacity: 0.7;
    transition: 0.5s;

    &-selected {
      @include theme-aware("border-color", "border_color");
      border-bottom: groove;
      opacity: 1;
    }

    a {
      @include theme-aware("color", "text_color");
    }

    svg {
      font-size: 1.2em;
      padding-top: 5px;
      margin: 0 5px;
    }
  }

  &-side {
    z-index: 99;
    position: fixed;
    visibility: hidden;
    animation: 1s fadeIn;
    animation-fill-mode: both;
    top: 30%;
    right: 0;

    ul {
      justify-content: center;
      align-content: flex-start;
      flex-flow: column;
    }

    li {
      margin-bottom: 10px;
      text-align: end;
    }

    a {
      @include theme-aware("color", "link_color");
      font-style: bold;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

@media (min-width: 1200px) {
  .menu {
    &-item {
      &:hover {
        font-size: 1.3em;
        opacity: 1;
      }
    }
  }
}
