@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}

@mixin gradient_background_content() {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      background: linear-gradient(
        to left,
        map-get(map-get($themes, $theme-name), primary_background_color),
        map-get(map-get($themes, $theme-name), secondary_background_color)
      );
    }
  }
}

@mixin gradient_background_app() {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      background: map-get(map-get($themes, $theme-name), primary_color);
      background: -webkit-linear-gradient(
        to bottom,
        map-get(map-get($themes, $theme-name), primary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), primary_color)
      );
      background: linear-gradient(
        to bottom,
        map-get(map-get($themes, $theme-name), primary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), secondary_color),
        map-get(map-get($themes, $theme-name), primary_color)
      );
    }
  }
}
