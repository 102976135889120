@import "scss/mixins";
@import "scss/variables";
@import "scss/about";
@import "scss/card";
@import "scss/contacts";
@import "scss/grid";
@import "scss/home";
@import "scss/input";
@import "scss/menu";
@import "scss/primitives";
@import "scss/timeline";
@import "scss/txt";

//libs
@import "react-toastify/dist/ReactToastify.css";
@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

.wip-div {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.wip-image {
  max-height: 200px;
  max-width: 200px;
}
