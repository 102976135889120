//colors
$themes: (
  light: (
    primary_color: #191654,
    secondary_color: #43c6ac,
    primary_background_color: #e0eafc,
    secondary_background_color: #cfdef3,
    border_color: #43c6ac,
    message_title: #888888,
    text_color: #f6f6f6,
    link_color: #191654,
    card_text_color: #000000,
    card_background: #ffffff,
  ),
  dark: (
    primary_color: #16222a,
    secondary_color: #3a6073,
    primary_background_color: #363636,
    secondary_background_color: #212121,
    border_color: #f6f6f6,
    message_title: #e2e2e2,
    text_color: #f6f6f6,
    link_color: #f6f6f6,
    card_text_color: #f6f6f6,
    card_background: #37474f,
  ),
);

//timeline
//$timeline_content-width: calc(40vw - 84px);
$timeline_content-width: 100%;
$timeline_margin: 20px;
$timeline_padding: 10px;
$timeline_spacing: 20px;
$timeline_bdrs: 6px;
$timeline_img-size: 150px;

//app
$max_width_responsive: 1200px;
$border_width: 3px;
$shadows: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
  0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
