.input {
  &-group {
    margin: 25px 0;
  }

  &-label {
    line-height: 1.4px;
  }

  &-txt {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 100%;
    height: 55px;
    font-size: 1em;
    padding: 10px;

    &:focus {
      @include theme-aware("outline", "secondary_color");
      @include theme-aware("border", "secondary_color");
      box-shadow: $shadows;
    }

    &-area {
      font-size: 1.2em;
      padding: 10px;
      margin-top: 10px;
      min-height: 190px;
      min-width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      &:focus {
        @include theme-aware("border", "secondary_color");
        outline: none;
        box-shadow: $shadows;
      }
    }
  }

  &-button {
    color: #fff;
    font-size: 1em;
    @include theme-aware("background", "secondary_color");
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 4px;
    box-shadow: $shadows;

    &:hover {
      @include theme-aware("background", "primary_color");
      transition: 1s;
      cursor: pointer;
    }
  }
}
