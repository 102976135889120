* {
  box-sizing: border-box;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: $timeline_margin auto;
  position: relative;
  width: 100vw;

  &-event {
    margin-bottom: $timeline_spacing;
    position: relative;
    display: flex;
    margin: $timeline_spacing 0;
    border-radius: $timeline_bdrs;
    align-self: center;
    width: 60vw;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
      .timeline-event-date {
        border-radius: 0 $timeline_bdrs $timeline_bdrs 0;
      }
      .timeline-event {
        .card {
          border-radius: $timeline_bdrs 0 0 $timeline_bdrs;
        }
      }
    }

    &-logo_container {
      min-height: $timeline_img-size;
      max-height: $timeline_img-size;
      min-width: $timeline_img-size;
      max-width: $timeline_img-size;
      padding: $timeline_padding;
    }

    &-title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.5px;

      span {
        margin-right: 5px;
      }

      svg {
        font-size: 14px;
      }
    }

    &-subtitle {
      font-size: 0.8rem;
      line-height: 1;
      text-transform: uppercase;

      font-weight: 300;
      letter-spacing: 1.5px;
    }

    &-date {
      font-size: 1.5rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 $timeline_spacing;
      border-radius: $timeline_bdrs 0 0 $timeline_bdrs;
    }

    &-logo {
      box-shadow: $shadows;
      position: relative;
      max-width: 100%;
      height: auto;
      border-radius: 50%;
      border-style: solid;
    }

    &-description {
      flex-basis: 60%;
    }
  }
}

@media (max-width: 1200px) {
  .timeline-event {
    flex-direction: column;
    align-self: center;
    width: 90vw;

    &.card {
      width: 100%;
    }

    &-logo_container {
      align-self: center;
    }

    &-logo {
      border-radius: $timeline_bdrs $timeline_bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: $shadows;
    }

    &-date {
      border-radius: 0;
      padding: $timeline_spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline-event-date {
        border-radius: 0;
        padding: $timeline_spacing;
      }

      .timeline-event-logo {
        border-radius: $timeline_bdrs $timeline_bdrs 0 0;
        margin: 0;
      }
    }
  }
}
